import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from '../../../../../store/utility';
import { backend_server_address } from '../../../../../backendServer';

export const annotationsAPI = createApi({
    reducerPath: 'annotationsAPI',
    baseQuery: axiosBaseQuery({ baseUrl: backend_server_address+'/api/annotations/'}),
    tagTypes: ['Annotation'],
    endpoints: (builder) => ({
        getAnnotations: builder.query({
            query: (params) => ({
                url: '',
                method: 'GET',
                withCredentials: true,
                params: params
            }),
            providesTags: (result) => {
                return result ? [...result.map((an: any) => ({ type:'Annotation' as const, id: an.id})), 'Annotation'] : [ 'Annotation' ];
            }
        }),
        createCompoundAnnotation: builder.mutation({
            query: (data) =>({
                url: 'compound/',
                method: 'PUT',
                withCredentials: true,
                data: data
            }),
        }),
    }),
});

export const {
    endpoints,
    useGetAnnotationsQuery,
    useCreateCompoundAnnotationMutation,
} = annotationsAPI;
export const annotationsAPIReducer = annotationsAPI.reducer;
export const annotationsAPIReducerPath = annotationsAPI.reducerPath;
export const annotationsAPIReducerMiddleware = annotationsAPI.middleware;